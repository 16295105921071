@use '../layout-bp';

textarea,
input[type=text],
input[type=email] {
  background: none;
  color: var(--fg);
  border: 2px solid var(--fg);
  display: block;
  width: 100%;
  @extend .text-light;

  &:focus {
    border-color: var(--root-accent) !important;
  }

  margin: 1rem 0;
  padding: 0.5rem;
  font-size: 0.85rem;
  @include layout-bp.layout-bp(gt-xs) {
    margin: 2rem 0;
    padding: 1rem;
    font-size: 1rem;
  }
  @include layout-bp.layout-bp(gt-sm) {
    margin: 3rem 0;
    padding: 1.2rem;
    font-size: 1.2rem;
  }
}

textarea {
  resize: none;

  height: 6rem;
  @include layout-bp.layout-bp(gt-xs) {
    height: 8rem;
  }
  @include layout-bp.layout-bp(gt-sm) {
    height: 10rem;
  }
}
