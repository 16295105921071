@use 'layout-bp';

footer {
  color: var(--root-fg);
  display: flex;
  justify-content: space-between;

  padding: 1rem;
  flex-direction: column-reverse;
  @include layout-bp.layout-bp(gt-xs) {
    padding: 1.5rem;
  }
  @include layout-bp.layout-bp(gt-sm) {
    padding: 2rem;
    flex-direction: row;
    align-items: center;
  }

  a {
    color: var(--accent);
  }

  small {
    line-height: 1.15rem;
    opacity: 0.75;

    margin-bottom: 1rem;
    @include layout-bp.layout-bp(gt-sm) {
      margin: 0;
    }
  }
}
