dialog {
  border: none;
  background-color: var(--root-fg);
  color: var(--root-bg);
  padding: 1.5rem;

  &::backdrop {
    backdrop-filter: blur(0.5rem);
    background-color: rgba(black, 0.5);
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
