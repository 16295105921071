@use "../layout-bp";

#benefits {
  ul {
    max-width: 85rem;
    display: grid;
    gap: 3rem;
    margin-top: 2.5rem;
    @extend .text-center;

    @include layout-bp.layout-bp(gt-xs) {
      grid-template-columns: repeat(4, 1fr);
      gap: 1.5rem;
      margin-top: 3rem;
    }
    @include layout-bp.layout-bp(gt-sm) {
      gap: 4rem;
      margin-top: 5.5rem;
    }
  }

  li {
    @extend .text-light;

    font-size: 0.9rem;
    line-height: 1.25rem;
    @include layout-bp.layout-bp(gt-sm) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    @include layout-bp.layout-bp(gt-md) {
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }

  img {
    filter: #{"invert(calc(1 - var(--inverted)))"};
    height: 6rem;
    display: block;
    margin: 0 auto 1rem;

    @include layout-bp.layout-bp(gt-xs) {
      height: 5rem;
      margin-bottom: 1.5rem;
    }
    @include layout-bp.layout-bp(gt-sm) {
      height: 6rem;
      margin-bottom: 2rem;
    }
  }
}
