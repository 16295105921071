@use 'transition';
@use 'layout-bp';

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--root-fg);
  width: 100%;
  height: var(--height);
  z-index: 10;
  position: fixed;
  top: 0;

  --height: 2.5rem;
  padding: 0 1rem;
  @include layout-bp.layout-bp(gt-xs) {
    --height: 3rem;
  }
  @include layout-bp.layout-bp(gt-sm) {
    padding: 0 1.5rem;
  }
  @include layout-bp.layout-bp(gt-md) {
    padding: 0 2rem;
    --height: 5rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: var(--root-bg);
    box-shadow: 0 0 0.75rem 0 var(--shadow);
    z-index: -1;
    @include transition.add(height);
  }

  &.attached:before {
    height: 100%;
  }

  #logo {
    display: flex;

    img {
      height: calc(var(--height) - 0.5rem);
      @include layout-bp.layout-bp(gt-xs) {
        height: calc(var(--height) - 1rem);
      }
    }

    & + span {
      display: flex;
      flex-direction: column;

      margin-left: 0.5rem;
      margin-right: auto;
      width: 6rem;
      @include layout-bp.layout-bp(gt-sm) {
        margin-left: 0.75rem;
        width: 8rem;
      }
      @include layout-bp.layout-bp(gt-md) {
        margin-left: 1rem;
        width: 12rem;
      }

      img {
        width: 100%;
      }

      a {
        white-space: nowrap;
        @extend .text-light;

        font-size: 0.6rem;
        margin-top: 0.35rem;
        @include layout-bp.layout-bp(gt-sm) {
          letter-spacing: 0.1rem;
        }
        @include layout-bp.layout-bp(gt-md) {
          font-size: 0.75rem;
          margin-top: 0.40rem;
          letter-spacing: 0.1rem;
        }
      }
    }
  }

  #menu {
    &-trigger {
      display: none;
      @include layout-bp.layout-bp(lt-md) {
        position: absolute;
        display: block;
        right: 1rem;
        top: 0.5rem;
        z-index: 2;
        opacity: 0;
        height: calc(100% - 1rem);
        width: calc(var(--height) - 1rem);

        &:checked {
          & ~ #menu-icon {
            &:before {
              transform: rotate(45deg);
            }

            &:after {
              transform: rotate(-45deg);
            }
          }

          & ~ nav {
            right: 0;
          }
        }
      }
    }

    &-icon {
      display: none;
      @include layout-bp.layout-bp(lt-md) {
        z-index: 1;
        font-size: 0;
        display: inline-block;
        height: calc(100% - 1rem);
        width: calc(var(--height) - 1rem);
        position: relative;
        cursor: pointer;

        &:before, &:after {
          content: '';
          position: absolute;
          width: 90%;
          height: 2px;
          background-color: var(--root-fg);
          top: calc(50% - 1px);
          left: 5%;
          @include transition.add(transform);
        }

        &:before {
          transform: translateY(-0.25rem);
        }

        &:after {
          transform: translateY(0.25rem);
        }
      }
    }
  }

  nav {
    flex-grow: 1;
    text-align: right;

    @include layout-bp.layout-bp(lt-md) {
      position: fixed;
      top: 0;
      right: -100%;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(0.5rem);
      background-color: rgba(black, 0.5);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      animation: none !important;
      @include transition.add(right);
    }

    a {
      position: relative;
      padding-bottom: 0.5rem;
      text-decoration: none;
      font-weight: 500;

      font-size: 1.25rem;
      @include layout-bp.layout-bp(gt-xs) {
        font-size: 1.5rem;
      }
      @include layout-bp.layout-bp(gt-sm) {
        font-size: 0.9rem;
      }
      @include layout-bp.layout-bp(gt-md) {
        font-size: 1rem;
      }

      &:before {
        content: '';
        width: 100%;
        bottom: 0;
        height: 2px;
        position: absolute;
        background-color: var(--root-fg);
        transform-origin: center;
        transform: scaleX(0);
        @include transition.add(transform);
      }

      &:focus:before,
      &:hover:before {
        transform: scaleX(0.5);
      }

      &.active:before {
        transform: none;
      }

      & + a {
        margin-top: 1.5rem;
        @include layout-bp.layout-bp(gt-xs) {
          margin-top: 2rem;
        }
        @include layout-bp.layout-bp(gt-sm) {
          margin-top: 0;
          margin-left: 1.25rem;
        }
        @include layout-bp.layout-bp(gt-md) {
          margin-left: 1.5rem;
        }
      }
    }
  }
}
