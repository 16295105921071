@use 'sass:math';
@use '../transition';
@use '../layout-bp';

.slider {
  position: relative;
  margin: 0 auto;
  padding: 0;
  user-select: none;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--fg);
    background-color: var(--bg);
    list-style: none;
    overflow: hidden;
    cursor: pointer;
    border-radius: var(--border);
    box-shadow: 0 0 1.5rem -0.5rem var(--shadow);
    @extend .text-light;
    @include transition.add(transform, filter, outline);

    &:first-child {
      overflow: auto;
    }

    &:focus {
      outline: var(--accent) solid 0.25rem;
    }

    $total: 4;
    @for $index from 0 through $total {
      $offset: $index * -1.5rem;
      &:nth-child(#{$index + 1}) {
        z-index: $total - $index;
        $val: math.div($index * 2, 10);
        filter: brightness(1 + $val) opacity(1 - $val);

        transform: translate(0, $offset + $index);
        @include layout-bp.layout-bp(gt-xs) {
          transform: translate($offset, $offset);
        }

        &:not(:first-child):hover {
          @include layout-bp.layout-bp(gt-xs) {
            transform: translate($offset, $offset - 1);
          }
        }
      }
    }
  }
}
