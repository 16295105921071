@use "sass:map";
@use "layout-bp";

* {
  outline: none;
  box-sizing: border-box;
}

body {
  color: var(--root-fg);
  background-color: var(--root-bg);
  font-family: 'Roboto', sans-serif;
}

main {
  position: relative;

  & > * {
    &:nth-child(odd) {
      --fg: var(--root-fg);
      --bg: var(--root-bg);
      --primary: var(--root-primary);
      --accent: var(--root-accent);
      --inverted: 0;
    }

    &:nth-child(even) {
      --fg: var(--root-bg);
      --bg: var(--root-fg);
      --primary: var(--root-accent);
      --accent: var(--root-primary);
      --inverted: 1;
    }

    overflow: hidden;
    color: var(--fg);
    background-color: var(--bg);

    padding: 2rem 0;
    @include layout-bp.layout-bp(gt-xs) {
      padding: 4rem 0;
    }
    @include layout-bp.layout-bp(gt-sm) {
      padding: 6rem 0;
    }
    @include layout-bp.layout-bp(gt-md) {
      padding: 8rem 0;
    }
  }
}

.container {
  list-style: none;
  margin: 0 auto;
  @each $bp in map.keys(layout-bp.$overlapping-gt) {
    @include layout-bp.layout-bp($bp) {
      width: map.get(layout-bp.$overlapping-gt, $bp);
    }
  }

  padding-left: 1rem;
  padding-right: 1rem;
  @include layout-bp.layout-bp(gt-xs) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  @include layout-bp.layout-bp(gt-sm) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @include layout-bp.layout-bp(gt-md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
