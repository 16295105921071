@use '../layout-bp';

#about {
  .container {
    column-gap: 5rem;
    grid-template-columns: repeat(2, 1fr);

    @include layout-bp.layout-bp(gt-sm) {
      display: grid;
      grid-template-rows: auto 1fr;
    }
  }

  picture {
    grid-row: 1 / 3;
    grid-column: 2;

    img {
      height: 100%;
      width: 100%;
      border-radius: var(--border);
      object-fit: cover;

      margin-top: 2rem;
      @include layout-bp.layout-bp(gt-xs) {
        margin-top: 1.5rem;
      }
      @include layout-bp.layout-bp(gt-sm) {
        margin-top: 0.5rem;
      }
    }
  }

  p {
    margin-top: 1rem;
    @include layout-bp.layout-bp(gt-xs) {
      margin-top: 1.5rem;
    }
    @include layout-bp.layout-bp(gt-sm) {
      margin-top: 2rem;
    }
  }
}
