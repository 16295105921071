@use '../transition';
@use '../layout-bp';

#principles {
  ul {
    display: grid;

    margin-top: 2rem;
    gap: 1rem;
    @include layout-bp.layout-bp(gt-xs) {
      margin-top: 3rem;
      gap: 2rem;
    }
    @include layout-bp.layout-bp(gt-sm) {
      margin-top: 5rem;
      gap: 3rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  li {
    background-color: var(--fg);
    color: var(--bg);
    padding: 1.2rem 1.5rem 1rem;
    border-radius: var(--border);
    @include transition.add(transform);
  }

  h3 {
    margin-top: 0.5rem;
  }

  img {
    width: 100%;
  }
}
