@mixin add($properties...) {
  $duration: 250ms;
  $timing: ease;

  @if (length($properties) > 0) {
    $value: ();

    @each $property in $properties {
      $value: append($value, $property $duration $timing, comma);
    }

    transition: $value;
  }
}
