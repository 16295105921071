@use 'sass:math';
@use '../transition';
@use '../layout-bp';

#approach {
  $count: 5;

  --height: 2rem;
  @include layout-bp.layout-bp(gt-xs) {
    --height: 2.5rem;
  }
  @include layout-bp.layout-bp(gt-sm) {
    --height: 2.5rem;
  }
  @include layout-bp.layout-bp(gt-md) {
    --height: 3.5rem;
  }

  ol {
    text-align: center;
    display: grid;

    grid-template-columns: 100%;
    margin-top: 2.5rem;
    gap: 3rem;
    @include layout-bp.layout-bp(gt-xs) {
      margin-top: 2rem;
      gap: 4rem;
    }
    @include layout-bp.layout-bp(gt-sm) {
      grid-template-columns: repeat($count, 1fr);
      margin-top: 4rem;
      gap: 1rem;
    }
  }

  li {
    &:hover {
      span {
        background-color: var(--primary);
        filter: none;

        &:after {
          border-left-color: var(--primary);
        }
      }

      img {
        filter: #{"invert(calc(1 - var(--inverted)))"};
      }
    }
  }

  $selector: '';
  $total: 5;
  @for $index from 1 through $total {
    $selector: if($index == 1, 'li', selector-nest($selector, '+ li'));
    #{$selector} {
      z-index: $total - $index;
      span {
        filter: opacity(0.2 * $index);
      }
    }
  }

  h3 {
    margin: 0 0 0.5rem calc(-1 * var(--height) / 2);
    @extend .text-regular;

    font-size: 1.25rem;
    @include layout-bp.layout-bp(gt-sm) {
      font-size: 1.35rem;
    }
    @include layout-bp.layout-bp(gt-md) {
      font-size: 1.5rem;
    }
  }

  p {
    margin-top: 0.75rem;
    padding: 0 0.75rem;

    @include layout-bp.layout-bp(gt-sm) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  span {
    background-color: var(--accent);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: calc(var(--height) * 2);
    width: calc(100% - var(--height));
    @include transition.add(background-color, filter);

    &:before, &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      border-top: var(--height) solid transparent;
      border-bottom: var(--height) solid transparent;
    }

    &:after {
      right: calc(-1 * var(--height) + 1px);
      border-left: var(--height) solid var(--accent);
      @include transition.add(border-left-color);
    }

    &:before {
      left: 0;
      border-left: var(--height) solid var(--bg);
    }

    img {
      height: 50%;
      filter: #{"invert(var(--inverted))"};
      margin-left: var(--height);
      @include transition.add(filter);
    }
  }
}
