@use '../layout-bp';

#case-studies {
  .container {
    max-width: 75rem;
    margin: 0 auto;
  }

  .slider {
    width: 100%;
    margin-top: 2rem;
    height: 70vh;
    @include layout-bp.layout-bp(gt-xs) {
      margin-top: 5rem;
    }
    @include layout-bp.layout-bp(gt-md) {
      width: 100%;
      margin-top: 7rem;
    }

    > * {
      @mixin offset($vertical, $horizontal) {
        padding: $vertical + 1.25rem $horizontal;
        img {
          width: calc(100% + $horizontal * 2);
          margin: 1.25rem -1 * $horizontal;
        }
      }

      @include offset(0, 1.5rem);
      @include layout-bp.layout-bp(gt-md) {
        @include offset(0.5rem, 2.5rem);
      }
    }
  }

  h3 {
    margin-top: 0;
  }

  picture + p {
    margin-top: 0;
  }
}
