@use 'layout-bp';
@use 'transition';
@import 'normalize.css/normalize';

:root {
  --root-bg: #{$root-bg};
  --root-fg: #{$root-fg};
  --root-primary: #{$root-primary};
  --root-accent: #{$root-accent};
  --shadow: #{$shadow};
  --border: #{$border};
  --inverted: 0;
}

html {
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

img {
  width: auto;
  height: auto;
}

h1 {
  @extend .text-regular;

  font-size: 1.6rem;
  line-height: 2rem;
  @include layout-bp.layout-bp(gt-xs) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  @include layout-bp.layout-bp(gt-sm) {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  @include layout-bp.layout-bp(gt-md) {
    font-size: 3rem;
    line-height: 4rem;
  }
}

h2 {
  @extend .text-light;

  font-size: 1.6rem;
  line-height: 1.8rem;
  @include layout-bp.layout-bp(gt-xs) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
  @include layout-bp.layout-bp(gt-sm) {
    font-size: 2.3rem;
    line-height: 3.3rem;
  }
  @include layout-bp.layout-bp(gt-md) {
    font-size: 2.8rem;
    line-height: 3.8rem;
  }

  & ~ p {
    @extend .text-light;

    font-size: 0.8rem;
    @include layout-bp.layout-bp(gt-xs) {
      font-size: 1rem;
    }
    @include layout-bp.layout-bp(gt-sm) {
      font-size: 1.25rem;
    }
    @include layout-bp.layout-bp(gt-md) {
      font-size: 1.5rem;
    }
  }
}

h3 {
  margin-top: 2.8rem;

  font-size: 1rem;
  line-height: 1.25rem;
  @include layout-bp.layout-bp(gt-xs) {
    font-size: 1.15rem;
    line-height: 1.5rem;
  }
  @include layout-bp.layout-bp(gt-sm) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  @include layout-bp.layout-bp(gt-md) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

p, li {
  margin-bottom: 0;

  font-size: 0.85rem;
  line-height: 1.25rem;
  margin-top: 0.75rem;
  @include layout-bp.layout-bp(gt-xs) {
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin-top: 1rem;
  }
  @include layout-bp.layout-bp(gt-sm) {
    font-size: 1rem;
    line-height: 2rem;
    margin-top: 1.25rem;
  }
  @include layout-bp.layout-bp(gt-md) {
    font-size: 1.1rem;
    line-height: 2.1rem;
  }
}

li li {
  margin: 0.5rem 0 0 0.25rem;
  list-style: square;
}

ul, ol {
  padding-left: 1.15rem;
}

a {
  text-decoration: none;
  color: var(--accent);
  box-shadow: 0 -1px 0 inset var(--accent);
  @include transition.add(color, box-shadow);

  &:focus,
  &:hover {
    color: var(--bg);
    box-shadow: 0 -2rem 0 inset var(--accent);
  }
}
