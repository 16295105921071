.text {
  &-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-light {
    font-weight: 300 !important;
  }

  &-regular {
    font-weight: 400 !important;
  }

  &-medium {
    font-weight: 500 !important;
  }

  @each $position in left center right {
    &-#{$position} {
      text-align: $position !important;
    }
  }

  @each $position in top middle bottom {
    &-#{$position} {
      vertical-align: $position !important;
    }
  }
}
