@use '../transition';
@use '../layout-bp';

.btn,
button {
  border-radius: 2rem;
  border: 0;
  color: var(--root-fg) !important;
  line-height: 1;
  background: var(--root-primary) radial-gradient(at 50% 200%, rgba(0, 0, 0, 0), var(--root-primary) 70%);
  text-decoration: none;
  display: inline-block;
  box-shadow: none !important;
  @include transition.add(background-color, filter);
  @extend .text-light;

  &[disabled] {
    cursor: not-allowed;
    filter: brightness(0.6);
  }

  &:not([disabled]) {
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: var(--root-accent);
    }

    &:active {
      filter: brightness(0.9);
    }
  }

  padding: 0.75rem 1.5rem;
  font-size: 0.85rem;
  @include layout-bp.layout-bp(gt-xs) {
    padding: 0.75rem 1.75rem;
    font-size: 1rem;
  }

  &:not(dialog &) {
    @include layout-bp.layout-bp(gt-sm) {
      padding: 0.75rem 2.25rem;
      font-size: 1.2rem;
    }
    @include layout-bp.layout-bp(gt-md) {
      padding: 1rem 2.5rem;
      font-size: 1.4rem;
    }
  }

  &.close {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    border-radius: 0.25rem;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: calc(100% - 0.4rem);
      height: 1px;
      display: block;
      left: 0.2rem;
      background-color: var(--root-fg);
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
