@import 'animate.css/source/_vars';
@import 'animate.css/source/_base';
@import 'animate.css/source/fading_entrances/fadeIn';
@import 'animate.css/source/fading_entrances/fadeInUp';
@import 'animate.css/source/fading_entrances/fadeInDown';
@import 'animate.css/source/fading_entrances/fadeInLeft';
@import 'animate.css/source/fading_entrances/fadeInRight';

.animated {
  @for $index from 0 through 17 {
    $delay: $index * 50 + 100 + ms;
    &.delay-#{$delay} {
      animation-delay: $delay;
    }
  }
}

.waiting:not(.animated) {
  opacity: 0;
}
