@use 'layout-bp';

#cookies {
  position: fixed;
  bottom: 1rem;
  max-width: 18.5rem;
  font-size: 0.85rem;
  padding: 0.75rem 2.75rem 0.5rem 0.75rem;
  box-shadow: 0 0 0.75rem -0.25rem var(--shadow);
  border-radius: var(--border);
  z-index: 100;
  right:0.5rem;
  left:0.5rem;

  @include layout-bp.layout-bp(gt-xs) {
    margin-right: 0.5rem;
  }

  button {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }
}
