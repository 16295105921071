@use '../transition';
@use '../layout-bp';

#home {
  position: relative;
  overflow: hidden;
  padding: 0 1rem;
  height: 100vh;

  .text {
    position: relative;
    z-index: 1;
    top: 10%;

    @include layout-bp.layout-bp(gt-xs) {
      position: absolute;
      left: 4.5rem;
      top: 15%;
    }
    @include layout-bp.layout-bp(gt-sm) {
      left: 7.5rem;
      top: 20%;
      width: 35rem;
    }
  }

  p {
    margin: 1.25rem 0 1.75rem;
    @include layout-bp.layout-bp(gt-xs) {
      margin: 2.5rem 0 3rem;
    }
  }

  .image {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    text-align: right;

    svg {
      height: 100%;

      * {
        @media (pointer: coarse) {
          @include transition.add(transform);
        }
      }
    }

    #overlay {
      fill: var(--bg);
    }
  }
}
