@use '../layout-bp';

#services {
  ul {
    display: grid;
    max-width: 85rem;
    gap: 3rem;

    margin-top: 3rem;
    @include layout-bp.layout-bp(gt-sm) {
      margin-top: 5rem;
    }
  }

  li {
    display: grid;
    margin-top: 0;

    gap: 1rem;
    @include layout-bp.layout-bp(gt-xs) {
      grid-template-columns: 1fr 2fr;
      gap: 2rem;
    }
    @include layout-bp.layout-bp(gt-sm) {
      gap: 5rem;
    }

    img {
      width: 100%;
      @include layout-bp.layout-bp(gt-sm) {
        height: 100%;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        margin-top: 0;
        display: flex;
        align-items: center;
      }
    }

    &:nth-child(odd) {
      @include layout-bp.layout-bp(gt-xs) {
        grid-template-columns: 2fr 1fr;
      }

      div {
        @include layout-bp.layout-bp(gt-xs) {
          grid-row: 1;
        }
      }
    }
  }
}
